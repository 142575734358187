<mat-card class="card" [ngStyle]="{'background-color': 'var(' + card.color + ')', 'color': card.white? 'whitesmoke': 'black'}">
  <div class="container">
    <div class="mat-h1">{{card.title}}
    </div>
    <div class="mat-body">{{card.body}}
    </div>
    <div class="small-button">
      <button *ngIf="card.buttonTitle" mat-raised-button class="button" [routerLink]="card.buttonLink" (click)="click()" routerLinkActive="active">{{card.buttonTitle}}
      </button>
    </div>
  </div>
</mat-card>

