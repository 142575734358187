
<mat-card *ngIf="!s.isMobile()" class="card" style="background-color: var({{card.color}});">
  <div *ngIf="card.imageLink" class="image">
    <img [src]="card.imageLink">
  </div>
  <div class="container">
    <div class="mat-h1 left">{{card.title}}
    </div>
    <div class="mat-body left">{{card.body}}
    </div>
    <div class="small-button">
      <button *ngIf="card.buttonTitle" mat-raised-button class="button" [routerLink]="card.buttonLink" routerLinkActive="active">
        {{card.buttonTitle}}
      </button>
    </div>
  </div>
</mat-card>
<app-centercard *ngIf="s.isMobile()"
                [card] = card
               >
</app-centercard>

