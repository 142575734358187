<!--<google-map></google-map>-->

<!--<img src="https://maps.googleapis.com/maps/api/staticmap?center=329MarchRoad,Kanata&zoom=14&size=400x400&key=AIzaSyD4duQ-nbCcWrf6TrsYz8_G4DP0pLWrbuo">-->

<mat-card class="card" [ngStyle]="{'background-color': card.color}">
  <div class="container">
    <div class="mat-h1">
      {{card.title}}
    </div>
    <div class="mat-body center">
      {{card.body}}
    </div>
    <div class="small-button">
      <button *ngIf="card.buttonTitle" mat-raised-button class="button" (click)="click()" routerLinkActive="active">
        {{card.buttonTitle}}
      </button>
    </div>
  </div>
</mat-card>
