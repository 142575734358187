<mat-card class="card" [ngStyle]="{'background-color': 'var(' + card.color + ')', 'color': card.white? 'whitesmoke': 'black'}">
  <div class="container">
    <div class="mat-h1">{{card.title}}
    </div>
    <div class="mat-body center">{{card.firstbody}}
    </div>
    <ul [ngStyle]="{'column-count': card.columns}" class="mat-body bulletlist">
      <li *ngFor="let bullet of card.bullets" class="mat-body bullet">
        {{bullet}}
      </li>
    </ul>
    <div class="mat-body center">{{card.secondbody}}
    </div>
    <div class="small-button">
      <button *ngIf="card.buttonTitle" mat-raised-button class="button" [routerLink]="card.buttonLink" routerLinkActive="active">{{card.buttonTitle}}
      </button>
    </div>
  </div>
</mat-card>
