<mat-toolbar class="color">
  <button mat-icon-button aria-label="Togggle Sidenav" (change)="click.emit()" >
    <mat-icon>menu</mat-icon>
  </button>

  <span class="spacer"></span>
  <div class="img-wrap">
    <img src="../../../assets/softsoleslogo.png" alt="soft soles">
  </div>
</mat-toolbar>
