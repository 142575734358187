<mat-card class="card" [ngStyle]="{'background-color': card.color}">
  <div class="container">
    <div class="image">
      <img [src]="card.imageLink" alt="Profile Picture">
    </div>
    <div class="mat-h1">
      {{card.title}}
    </div>
    <div class="mat-body">
      {{card.body}}
    </div>
  </div>
</mat-card>
