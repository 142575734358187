<app-displaybar buttonTitle="Book Now" text="Best Practices. Best Equipment. "></app-displaybar>
<ng-container *ngFor="let bullet of onebullet">
  <app-bulletcard [card]="bullet"></app-bulletcard>
</ng-container>
<ng-container *ngFor="let card of onecards ">
  <app-centercard [card]="card"></app-centercard>
</ng-container>
<ng-container *ngFor="let bullet of twobullet">
  <app-bulletcard [card]="bullet"></app-bulletcard>
</ng-container>
