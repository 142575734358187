<mat-card class="parallaxcard" [ngStyle]="{'background-image': card.color}">
  <div class="container">
    <div class="mat-h1">
      {{card.title}}
    </div>
    <div class="mat-body">
      {{card.body}}
    </div>
    <div class="small-button" >
      <button *ngIf="card.buttonTitle" class="white button" mat-raised-button [routerLink]="card.buttonLink" routerLinkActive="active">
        {{card.buttonTitle}}
      </button>
    </div>
  </div>
</mat-card>
